<template>
  <page-container class="subordinate-container">
    <div
        class="subordinate-item"
        v-for="{ name, img, level, time } in dataList"
    >
      <van-image class="subordinate-img" :src="img"/>
      <div class="subordinate-name">{{ name }}</div>
      <div class="subordinate-sub-info">
        <span>{{ level }}</span>
        <span>{{ time }}</span>
      </div>
    </div>
  </page-container>
</template>

<script>
import { getSubordinateListTestApi } from '@/test/subordinate';

export default {
  name: 'subordinate',
  data() {
    return {
      dataList: [],
    };
  },
  created() {
    getSubordinateListTestApi().then(res => {
      this.dataList = Array.isArray(res) ? res : [];
    });
  }
};
</script>

<style lang="less" scoped>
.subordinate-container {

  .subordinate-item {
    position: relative;
    padding: 18px 16px 18px 78px;
    background-color: #fff;

    & + .subordinate-item:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: 18px;
      left: 18px;
      border-top: 1px solid #eaeaea;
    }

    .subordinate-img {
      position: absolute;
      z-index: 1;
      left: 18px;
      width: 44px;
      height: 44px;
      border-radius: 100%;
      overflow: hidden;
    }

    .subordinate-name {
      padding: 2px 0;
      line-height: 17px;
      font-size: 13px;
      color: #333;
    }

    .subordinate-sub-info {
      display: flex;
      justify-content: space-between;
      padding: 2px 0;
      line-height: 17px;
      font-size: 13px;
      color: #333;
    }
  }
}
</style>
