const subordinateList = [
  {
    'img': 'img/test1.jpg',
    'name': '用户一',
    level: '一级',
    time: '2022-04-11',
  },
  {
    'img': 'img/test2.jpg',
    'name': '用户一',
    level: '一级',
    time: '2022-04-11',
  },
  {
    'img': 'img/test3.jpg',
    'name': '用户一',
    level: '一级',
    time: '2022-04-11',
  },
  {
    'img': 'img/test1.jpg',
    'name': '用户一',
    level: '一级',
    time: '2022-04-11',
  }
];

export function getSubordinateListTestApi() {
  return new Promise(resolve => setTimeout(() => resolve(subordinateList), 123));
}
